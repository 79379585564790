import { IConfiguration } from './configuration';

export const environment: IConfiguration = {
    production: false,
    appVersion: '1.0.0',
    stripe: {
        privateKey:
            'pk_test_51LucKrLiqSZoNeCk59iPPKQd30bM7mpIGPAt9bpRpUaOW9hxHSLsobP2y12iU30AIPUe7U5NJoCi7BBI1EtGVBoi00OJdEwVDe',
        returnUrl: 'https://iiubeo.fr/payment-intent/thank-you',
    },
    api: {
        baseUrl: 'https://api.iiubeo.fr',
    },
};
